@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
  width: 100%;
  z-index: 0;
}

.page {
  z-index: -1;
  position: relative;
  height: 0;
}

.fixed-centered-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease;
  z-index: -1;
}

.top-offset {
  position: absolute;
}

.child-offset {
  position: absolute;
  top: 2rem;
}

.logo-big {
  width: 55%;
  max-width: 310px;
  margin-top: 3%;
  margin-bottom: 1.5%;
}

.logo-small {
  width: 15%;
  max-width: 300px;
  margin: 1rem;
}

.reel {
  margin-top: 1.5%;
}

.nav-center {
  text-decoration: none;
  color: black;
  z-index: 1;
}
.nav-center a {
  font-weight: lighter;
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  transition: font-size 0.05s, color 0.2s;
  transition-timing-function: ease-in-out;
  z-index: 2;
}
.nav-center :hover {
  color: purple;
  font-size: 1.4rem;
  transition: font-size 0.1s, color 0.3s;
  transition-timing-function: ease-in-out;
}
.nav-center :active {
  font-size: 1.2rem;
}

.nav-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  z-index: 0;
  padding: 0.5rem;
}
.nav-container a {
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  transition: font-size 0.08s, color 0.2s;
  transition-timing-function: ease-in-out;
  z-index: 5;
  transform: scale(2);
}
.nav-container a:active {
  font-size: 0.9rem;
}
.nav-container a:hover {
  color: purple;
  font-size: 1.1rem;
  cursor: pointer;
  transition: font-size 0.1s, color 0.3s;
  transition-timing-function: ease-in-out;
}

.contact-info {
  margin-top: 2rem;
  text-align: center;
}
.contact-info p {
  margin: 1rem;
}

.client-name {
  position: relative;
  z-index: 2;
}
.client-name h2 {
  font-weight: normal;
  z-index: 2;
}
.client-name :hover {
  color: purple;
  font-size: 1.55rem;
  cursor: pointer;
  transition: font-size 0.1s, color 0.3s;
  transition-timing-function: ease-in-out;
}
.client-name :active {
  font-size: 1.4rem;
}

.work-item-name h3 {
  font-weight: lighter;
}

.full-iframe {
  width: 100%;
  height: 100vh;
}